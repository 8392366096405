import React from 'react'
import PropTypes from 'prop-types'
import {
  Search
} from '@scuf/common'

import {
  InputsContainer
} from '../../tags.styles'
import ActionBar from 'Components/ActionBar'
import ActionContainer from '../action-container'
import { translate } from 'react-translate'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'
import { SimpleRow } from 'Components/AssetManagementBase/asset-management-base.styles'
import { UnlicensedTooltip } from 'Containers/AssetsDetails/collapse'

const Controls = ({
  editRemove,
  onAdd,
  onEdit,
  onRemove,
  permissions,
  flags,
  deviceDbStatus,
  t,
  ...rest
}) => {
  const isUnLicensedDevice = (flags.licensing && deviceDbStatus === 'unlicensed')
  return (
    <InputsContainer>
      <ActionBar>
        <ActionBar.Section>
          <div hidden={true}>
            <Search />
          </div>
        </ActionBar.Section>
        <UnlicensedTooltip
          position='top center'
          content= 'renew'
          disabled={deviceDbStatus === 'active'}
          >
            <SimpleRow disabled={isUnLicensedDevice}>
              <ActionBar.Section>
                {permissions['tagging:create'] &&
                  <ActionContainer
                    description={t('AddTag')}
                    disabled={false}
                    label={t('NewTag')}
                    data-testid='newTagButton'
                    name='add'
                    onClick={onAdd}
                    root='building'
                    size='small'
                  />}
                {permissions['tagging:edit'] &&
                  <ActionContainer
                    description={t('Edit')}
                    disabled={!editRemove}
                    label={t('Edit')}
                    data-testid='editTagButton'
                    name='edit'
                    onClick={onEdit}
                    root='common'
                    size='small'
                  />}
                {permissions['tagging:delete'] &&
                  <ActionContainer
                    description={t('RemoveFromDevices')}
                    disabled={!editRemove}
                    label={t('RemoveFromDevices')}
                    data-testid='deleteTagButton'
                    name='delete'
                    onClick={onRemove}
                    root='common'
                    size='small'
                  />}
              </ActionBar.Section>
            </SimpleRow>
            </UnlicensedTooltip>
      </ActionBar>
    </InputsContainer>
  )
}

Controls.defaultProps = {
  t: PropTypes.func,
  flags: defaultFlags,
}

Controls.propTypes = {
  editRemove: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  flags: flagsPropTypes,
  permissions: PropTypes.object,
  t: () => null
}

export default translate('TaggingView')(Controls)
