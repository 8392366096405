import { useState, useEffect } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-translate'
import { hasPackageCarSubscribedFeature } from 'Selectors/PermissionsSelector'
import { TypeApi, DeviceApi } from '../../../../Services'

export function getDeviceManufacturers() {
  const getManufacturers = async () => {
    let response
    try {
      response = await DeviceApi.getDeviceManufacturers();
      return response?.data?.deviceManufacturer;
    } catch (e) {
      throw new Error('An error occurred while loading device Manufacturer.')
    }
  }
  return { getManufacturers }
}

export const useDeviceModels = (deviceType) => {
  const [types, setTypes] = useState({})
  const [models, setModel] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const t = useTranslate('DeviceTypes')
  const hasPackageCarSubscribedFeatures = useSelector( hasPackageCarSubscribedFeature )
  useEffect(() => {
    const getDevices = async () => {
      const response = await TypeApi.getDevicesModels()
      if (response.ok) {
        if(!hasPackageCarSubscribedFeatures){
          delete response.data?.entities.devices['rfidreader']
        }
        const types = get(response, 'data.entities.devices', {})

        const assets = Object.keys(types)
          .filter(type => type !== 'industrialprinter' && type !== 'mobileprinter')
          .map(type => ({
            text: t(type),
            value: type
          }))
        assets.push({ value: 'printer', text: t('printer') })

        setTypes(types)
        setAssetTypes(assets)
      }
    }
    getDevices()
  }, [])

  useEffect(() => {
    fetchCurrentModel(deviceType)
  }, [deviceType, types])

  const fetchCurrentModel = (deviceType) => {
    let models
    if (deviceType) {
      if (deviceType === 'printer') {
        const industrial = get(types, 'industrialprinter.deviceModelsV2', [])
        const mobile = get(types, 'mobileprinter.deviceModelsV2', [])
        models = industrial.concat(mobile)
      } else {
        models = get(types, `${deviceType}.deviceModelsV2`, [])
      }
    } else {
      const uniqueModelMap = Object.keys(types)
        .reduce((arr, type) => {
          const modelsArr = get(
            types,
            `${type}.deviceModelsV2`,
            []
          ).reduce((modelsObject, model) => {
            if (model.displayName) {
              return {
                ...modelsObject,
                [model.displayName]: model.name
              }
            }
            return modelsObject
          }, {})
          return {
            ...arr,
            ...modelsArr
          }
        }, {})
      models = Object.keys(uniqueModelMap)
        .map(u => ({
          displayName: u,
          name: uniqueModelMap[u]
        }))
    }
    const model = models
      .filter(model => model.displayName)
      .map(model => {
        return {
          text: model.displayName,
          value: model.name
        }
      })
    setModel(model)
  }

  return {
    models,
    assetTypes,
    fetchCurrentModel
  }
}
